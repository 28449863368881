import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SettingsLayoutComponent } from './settings-layout/settings-layout.component'
import { SettingsTabsComponent } from "./settings-tabs/settings-tabs.component";

const dbRoutes: Routes = [
  {
    path: "",
    component: SettingsLayoutComponent,
  },
  {
    path: "tabs/:id",
    component: SettingsTabsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(dbRoutes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule {}