import { Component, Input, OnInit } from "@angular/core";
import { questionsList } from "src/app/app-functions";

@Component({
  selector: "app-game-setup",
  templateUrl: "./game-setup.component.html",
  styleUrls: ["./game-setup.component.scss"],
})
export class GameSetupComponent implements OnInit {
  @Input() pageName;
  questionsList = questionsList();
  selectedQuestion = {};
  avaliableKpiList:any = [
    "Active Time",
    "Productive Time",
    "Production Tool",
    "Production Tool Opportunity",
    "Exception Time",
    "Application Time",
    "Volume",
    "Non productive Time",
  ];

  points: any = [
    100,150,200,250,300,350,400,450,500,1000,1500,2000
  ]
  constructor() {}

  ngOnInit() {
    this.avaliableKpiList = this.avaliableKpiList.map((e,i) => { return {"key":i + 1 , value:e}})
  }

  onQuestionClick(qstn) {
    this.selectedQuestion = { ...this.questionsList[qstn - 1], qstn };
  }
}
