import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  @Input() showNavbar:boolean;
  @Input() kpiSettings: boolean;
  @Input() isMainNav: boolean = false;
  constructor(private acRoute:ActivatedRoute, private router: Router) { }

  
  ngOnInit() {
    
  }

}
