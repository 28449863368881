class CreateLevels {
  constructor(
    private Measure,
    private Rule,
    private Quantity,
    private Points,
    private Weightage
  ) {}
}

class CreateMainData {
  constructor(private KPI, private Trigger, private Time) {}
}

class CreateQuestion {
  constructor(
    private questionType,
    private points,
    private question,
    private options,
    private selected,
    private isUrl = false
  ) {}
}

class createKpiTabsList {
  constructor(
    private description,
    private workday,
    private time,
    private levels
  ) {}
}

class CreateKpi {
  constructor(
    private title,
    private timeLength,
    private rule,
    private quantity,
    private points,
    private weightage
  ) {}
}

class CreateNavPage {
  constructor(
    private title,
    private time,
    private description,
    private budget,
  ) {}
}

class CreateSwNavPage {
  constructor(
    private title,
    private time,
    private description,
    private budget,
    private kpiData
  ) {}
}

export function navPageData(){
  return {
    "octCorporateCulture": new CreateNavPage("Trivia - Oct Corporate Culture","10/01/2021 to 10/31/2021","Improve Corporate Culture","5000"),
    "sepLeadership": new CreateNavPage("Trivia - Sep Leadership","09/01/2021 to 09/30/2021","Improve Leadership Capabilities","5000"),
    "augustTeamWork": new CreateNavPage("Trivia - August Team Work","08/01/2021 to 08/31/2021","Improve Team Work","5000"),
    "julDimensions": new CreateNavPage("Trivia - Jul Dimensions","07/01/2021 to 07/31/2021","Improve Leadership Dimensions","5000"),
    "junAnchors": new CreateNavPage("Trivia - Jun Anchors","06/01/2021 to 06/30/2021","Improve Behavioral Anchors","5000"),

  }
}

export function navSwPageData(){
  return {
    "q4Punctuality": new CreateSwNavPage("Spin The Wheel - Q4 Punctuality","10/1/2021 to 12/30/2021","Improving Q4 Punctuality","15000",{
      kpiDes:'Punctuality',
      timeDecay:'No',
      trigger:'Every morning at 8:00 am',
      list:[
        {
          measure:'Workstation first login time',
          target:'8:00 am'
        },
        {
          measure:'Consecutive Weeks',
          target:'>5 Days'
        }
      ]
    }),
    "q3ReduceOutlook": new CreateSwNavPage("Spin The Wheel - Q3 Reduce Outlook","07/01/2021 to 09/30/2021","Reduce time spent in Outlook","15000",{
      kpiDes:'Working Time',
      timeDecay:'No',
      trigger:'Every morning at 8:00 am',
      list:[
        {
          measure:'Hours spent in Outlook',
          target:'10% reduction per week'
        },
        {
          measure:'Consecutive Weeks',
          target:'Acheived 10% reduction for 2 weeks'
        }
      ]
    }),
    "q2CustomerSatisfaction": new CreateSwNavPage("Spin the Wheel - Q2 Customer Satisfaction","10/01/2021 to 12/31/2021","Improving Q4 Punctuality","5000",{
      kpiDes:'Customer Satisfaction Score',
      timeDecay:'No',
      trigger:'Every Workday morning',
      list:[
        {
          measure:'response score  greater than 5 on previous day',
          target:'10 times'
        },
        {
          measure:'response score  greater than 10 on previous day',
          target:'50'
        }
      ]
    }),
    "q2Volume": new CreateSwNavPage("Spin the Wheel - Q2 Volume","10/01/2021 to 12/31/2021","Improving Q4 Punctuality","5000",{
      kpiDes:'Completed Contacts',
      timeDecay:'No',
      trigger:'Every Workday morning',
      list:[
        {
          measure:'Contacts Completed on prior work day',
          target:'15'
        },
        {
          measure:'Contacts Completed on prior work day',
          target:'25'
        }
      ]
    }),
  }
}

export function spinthewheelJson() {
  return {
    productionTollTime: [
      new CreateMainData(
        "Time Team spends on production application",
        "Every Monday at 8:00 AM",
        "Yes"
      ),
      new CreateLevels(
        "Number of hours per week",
        "Minimum of",
        "5 hrs",
        "250",
        "10"
      ),
      new CreateLevels("Number of hours", "Minimum of", "6 hrs", "350", "10"),
    ],
    volume: [
      new CreateMainData(
        "No of order completed",
        "1st Working day of the November",
        "No"
      ),
      new CreateLevels(
        "Number of orders completed last month",
        "Between",
        "30 to 40",
        "250",
        "10"
      ),
      new CreateLevels(
        "Number of orders completed last  20 business days",
        "Between",
        "40 to 50",
        "350",
        "10"
      ),
    ],
    trainingTime: [
      new CreateMainData(
        "Time spent knowing about products",
        "Thursday at 8:00 AM",
        "Yes"
      ),
      new CreateLevels(
        "Number of hours spent lastmonth",
        "Minimum of",
        "5hrs",
        "250",
        "10"
      ),
      new CreateLevels("Number of hours", "Minimum of", "6 hrs", "350", "10"),
    ],
    collaborationTime: [
      new CreateMainData("Time spent in Meetings", "Friday at 3:00 PM", "No"),
      new CreateLevels(
        "Number of hours spent per week",
        "Less than",
        "10 hours",
        "250",
        "10"
      ),
      new CreateLevels(
        "Number of hours spent per week",
        "Less than",
        "8 hours",
        "350",
        "10"
      ),
    ],
    productiveTime: [
      new CreateMainData(
        "Time spent on business applications",
        "Friday at 3:00 PM",
        "No"
      ),
      new CreateLevels(
        "Number of hours spent per week",
        "More  than",
        "25 hours",
        "250",
        "10"
      ),
      new CreateLevels(
        "Number of hours spent per week",
        "More  than",
        "30 hour",
        "350",
        "10"
      ),
    ],
  };
}

export function triviaJson() {
  return {
    trainingTime: [
      new CreateMainData(
        "Time spent knowing about Cyber Security",
        "Every Monday at 8:00 AM",
        "Yes"
      ),
      new CreateLevels(
        "Number of hours per month",
        "Minimum of",
        "5 hrs",
        "250",
        "10"
      ),
      new CreateLevels(
        "Number of hours per month",
        "Minimum of",
        "6 hrs",
        "350",
        "10"
      ),
    ],
    noOfOrders: [
      new CreateMainData(
        "No of order completed",
        "1st Working day of the November",
        "No"
      ),
      new CreateLevels(
        "Number of orders completed last week",
        "Between",
        "10 to 15",
        "250",
        "10"
      ),
      new CreateLevels(
        "Number of orders completed last  20 business days",
        "Between",
        "15 to 20",
        "350",
        "10"
      ),
    ],
    applicationTime: [
      new CreateMainData("Time spent in Excel", "Thursday at 8:00 AM", "Yes"),
      new CreateLevels(
        "Number of hours spent lastmonth",
        "Minimum of",
        "5hrs",
        "250",
        "10"
      ),
      new CreateLevels("Number of hours", "Minimum of", "5hrs", "350", "10"),
    ],
    overTime: [
      new CreateMainData("Time spent in Meetings", "Friday at 3:00 PM", "No"),
      new CreateLevels(
        "Number of hours spent per week",
        "Less than",
        "2 hours",
        "250",
        "10"
      ),
      new CreateLevels("Number of hours", "Less than", "1 hour", "350", "10"),
    ],
    productiveTime: [
      new CreateMainData(
        "Time spent on business applications",
        "Friday at 3:00 PM",
        "No"
      ),
      new CreateLevels(
        "Number of hours spent per week",
        "More  than",
        "25 hours",
        "250",
        "10"
      ),
      new CreateLevels("Number of hours", "More  than", "25 hour", "350", "10"),
    ],
  };
}

export function jeapordyJson() {
  return {
    volume: [
      new CreateMainData("No of order completed", "Friday at 3:00 PM", "No"),
      new CreateLevels(
        "Number of orders completed last month",
        "Between",
        "30 to 40",
        "250",
        "10"
      ),
      new CreateLevels(
        "Number of orders completed last  20 business days",
        "Between",
        "40 to 50",
        "350",
        "10"
      ),
    ],
    overTime: [
      new CreateMainData("Time spent in Meetings", "Friday at 3:00 PM", "No"),
      new CreateLevels(
        "Number of hours spent per week",
        "Less than",
        "2 hours",
        "250",
        "10"
      ),
      new CreateLevels("Number of hours", "Less than", "1 hour", "350", "10"),
    ],
    productiveTime: [
      new CreateMainData(
        "Time spent on business applications",
        "Friday at 3:00 PM",
        "No"
      ),
      new CreateLevels(
        "Number of hours spent per week",
        "More  than",
        "25 hours",
        "250",
        "10"
      ),
      new CreateLevels(
        "Number of hours",
        "More  than",
        "25 hours",
        "350",
        "10"
      ),
    ],
    casesAudited: [
      new CreateMainData("Cases Audited", "Friday at 3:00 PM", "No"),
      new CreateLevels(
        "Number of cases audited per week",
        "Minimum of",
        "5",
        "250",
        "10"
      ),
      new CreateLevels(
        "Number of cases audited per week",
        "Minimum of",
        "7",
        "350",
        "10"
      ),
    ],
    transactionsComplete: [
      new CreateMainData("Cases touched", "Friday at 3:00 PM", "No"),
      new CreateLevels(
        "Number of cases per week",
        "Minimum of",
        "20",
        "250",
        "10"
      ),
      new CreateLevels(
        "Number of cases per week",
        "Minimum of",
        "20",
        "350",
        "10"
      ),
    ],
  };
}

export function questionsList() {
  return [
    new CreateQuestion(
      "Single Select",
      "100",
      "Which is the Oldest tree on planet",
      ["Oak", "Cedar", "Bamboo", "Teak"],
      "Teak",
      true
    ),
    new CreateQuestion(
      "Multi Select",
      "100",
      "Which is the Oldest city on planet",
      ["Oak", "Cedar", "Bamboo", "Teak"],
      "Teak,Bamboo",
      true
    ),
    new CreateQuestion(
      "Single Select",
      "100",
      "When is our company incorporated?",
      ["1990", "1980", "1975", "2001"],
      "1990",
      true
    ),
    new CreateQuestion(
      "Single Select",
      "100",
      "Which is the cheapest customer account type?",
      ["Oak", "Cedar", "Bamboo", "Teak"],
      "Teak",
      true
    ),
    new CreateQuestion(
      "Mutli Select",
      "200",
      "select all products that have 3hrs SLA",
      ["Product A", "Product B", "Product C", "Product D"],
      "Product B,Product D",
      true
    ),
    new CreateQuestion(
      "Single Select",
      "200",
      "Do we offer coverage for lost items?",
      ["Yes", "No"],
      "Yes"
    ),
    new CreateQuestion(
      "Mutli Select",
      "400",
      "select all products that are applicable for age less than 18",
      ["Product A", "Product B", "Product C", "Product D"],
      "Product B,Product D",
      true
    ),
    new CreateQuestion(
      "Single Select",
      "400",
      "Do you need submit a receipt for expenses?",
      [
        "More than 100",
        "Everythnig",
        "Only if alcohol is included",
        "More than 500",
      ],
      "More than 500"
    ),
    new CreateQuestion(
      "Multi Select",
      "200",
      "Select the type of phising email you received",
      [
        "looks like an internal email",
        "email asking to click to see rewards",
        "Asking to buy gift card ",
        "Trying to get corporate card",
      ],
      "looks like an internal email,email asking to click to see rewards"
    ),
  ];
}

export function createKPISWtabs() {
  return {
    productionToolTime: new createKpiTabsList(
      "Time spent on business applications",
      "Monday",
      "8:00 AM",
      [
        new CreateKpi(
          "Productive Time",
          "Day",
          "More than",
          "6 Metric:hrs",
          "200",
          "10"
        ),
        new CreateKpi(
          "Productive Time",
          "Day",
          "More than",
          "7 Metric:hrs",
          "250",
          "10"
        ),
      ]
    ),
    volume: new createKpiTabsList("Orders handled", "Monday", "8:00 AM", [
      new CreateKpi(
        "No Of Orders Handled",
        "Day",
        "More than",
        "6 Metric:Orders",
        "200",
        "10"
      ),
      new CreateKpi(
        "No Of Orders Handled",
        "Day",
        "More than",
        "7 Metric:hrs",
        "250",
        "10"
      ),
    ]),
    trainingTime: new createKpiTabsList(
      "Product Education",
      "Monday",
      "8:00 AM",
      [
        new CreateKpi(
          "Complete Elearning Certifications",
          "Week",
          "More than",
          "3",
          "200",
          "10"
        ),
        new CreateKpi(
          "Complete Elearning Certifications",
          "Day",
          "More than",
          "7",
          "250",
          "10"
        ),
      ]
    ),
    collaborationTime: new createKpiTabsList(
      "Improve Collaboration",
      "Monday",
      "8:00 AM",
      [
        new CreateKpi(
          "Attend Town Hall",
          "Quarter",
          "More than",
          "1",
          "200",
          "10"
        ),
        new CreateKpi(
          "Attend Give Back Days",
          "Quarter",
          "More than",
          "1",
          "250",
          "10"
        ),
      ]
    ),
    productiveTime: new createKpiTabsList(
      "Improve Productive Time",
      "Monday",
      "8:00 AM",
      [
        new CreateKpi("Productive Time", "Week", "More than", "6", "200", "10"),
        new CreateKpi(
          "Productive Time",
          "Week",
          "More than",
          "36",
          "250",
          "10"
        ),
      ]
    ),
  };
}

export function createKPITriviatabs() {
  return {
    trainingTime: new createKpiTabsList(
      "Cyber Security Education",
      "Monday",
      "8:00 AM",
      [
        new CreateKpi(
          "Complete Elearning Certifications",
          "Week",
          "More than",
          "3",
          "200",
          "10"
        ),
        new CreateKpi(
          "Complete Elearning Certifications",
          "Week",
          "More than",
          "7",
          "250",
          "10"
        ),
      ]
    ),
    noOfOrders: new createKpiTabsList("Orders handled", "Monday", "8:00 AM", [
      new CreateKpi(
        "No Of Orders Handled",
        "Day",
        "More than",
        "6 Metric:Orders",
        "200",
        "10"
      ),
      new CreateKpi(
        "No Of Orders Handled",
        "Week",
        "More than",
        "7 Metric:hrs",
        "250",
        "10"
      ),
    ]),
    applicationTime: new createKpiTabsList(
      "Business Application Time",
      "Monday",
      "8:00 AM",
      [
        new CreateKpi(
          "Complete Elearning Certifications",
          "Week",
          "More than",
          "3",
          "200",
          "10"
        ),
        new CreateKpi(
          "Complete Elearning Certifications",
          "Week",
          "More than",
          "7",
          "250",
          "10"
        ),
      ]
    ),
    overTime: new createKpiTabsList("Reduce Over Time", "Monday", "8:00 AM", [
      new CreateKpi("Over Time", "Month", "Less than", "10", "200", "10"),
      new CreateKpi("Over Time", "Quarter", "More than", "15", "250", "10"),
    ]),
    productiveTime: new createKpiTabsList(
      "Improve Productive Time",
      "Monday",
      "8:00 AM",
      [
        new CreateKpi("Productive Time", "Day", "More than", "6", "200", "10"),
        new CreateKpi(
          "Productive Time",
          "Week",
          "More than",
          "36",
          "250",
          "10"
        ),
      ]
    ),
  };
}

export function titlesList() {
  return [
    "activeTime",
    "completeElearningCertifications",
    "businessApplicationTime",
    "exceptionTime",
    "productionToolTime",
    "produtionToolOpportunity",
    "overTime",
    "productiveTime",
    "nonProductiveTime",
    "collaborationTime",
    "meetingTime",
    "noOfOrdersHandled",
    "noOfCalls",
    "noOfTickets",
    "noOfLevelIITickets",
    "noOfLevelIIITickets",
    "noOfErrors",
    "noOfbugsFound",
    "noOfCRsReviewed",
    "noOfCRsSubmitted",
    "noOftimesCodeIfBroken",
    "codeCoveragePercentage",
    "noOfHoursWorkedPerDay",
    "numberOfDaysWorkedWithoutASickLeave",
    "attendTownHall",
    "attendGiveBackDays"
  ];
}
export const timeLengthList = [
"Hour",
"Day",
"Week",
"Month",
"Quarter"

]

export const workdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const timesList = [
  "8:00 AM",
  "9:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "1:00 PM",
  "2:00 PM",
  "3:00 PM",
  "4:00 PM",
  "5:00 PM",
  "6:00 PM",
  "7:00 PM",
  "8:00 PM",
  "9:00 PM",
];

export const recurring = [
  "Every 1 Week",
  "Every 2 Weeks",
  "Every 3 Weeks",
  "Every 4 Weeks",
  "Every 5 Weeks",
  "Every 6 Weeks",
  "Every 7 Weeks",
  "Every 8 Weeks",
  "Every Month",
  "Every Day of Week",
  "Every Weekend",
];

export const rules = [
  "Minimum Of",
  "More than",
  "Equal to",
  "Less than",
  "Not greater then",
  "Between",
];

export const quantityListLevel1 = [
  "1 hour",
  "2 hours",
  "3 hours",
  "4 hours",
  "5 hours",
  "6 hours",
];

export const quantityListLevel2 = [
  "4 weeks",
  "6 weeks",
  "8 weeks",
  "12 weeks",
  "16 weeks",
];

export const weightList = [
  "10",
  "20",
  "30",
  "40",
  "50",
  "60",
  "70",
  "80",
  "90",
  "100",
];
export const metricList = [
  "Hours",
  "Orders",
  "Days",
  "Tasks",
  "Minutes",
  "issues",
  "tasks",
];


export const newQuestionsJson = {
    "julDimensions":[{
       qstn:'Which Extraorderinary Leadership Dimension is represented by the statement, “Connects the Group to the Outside World”?',
       a1:'Character',
       a2:'Leading Change',
       a3:'Interpersonal Skills',
       a4:'Focus on Results',
       a5:'Personal Capability',
       cw:'2',
       cwr:'That’s correct. Connects the Group to the Outside World is linked to Leading Change.',
       cpoints:'10',
       icwr:'That’s incorrect. Connects the Group to the Outside World is linked to Leading Change.',
       icpoints:'-5',
    },{
      qstn:'Which Extraorderinary Leadership Dimension is represented by the statement, “Inspires and Motivates Others to High Performance”?',
      a1:'Character',
      a2:'Leading Change',
      a3:'Interpersonal Skills',
      a4:'Focus on Results',
      a5:'Personal Capability',
      cw:'2',
      cwr:'That’s correct. Inspires and Motivates Others to High Performance is linked to Interpersonal Skills.',
      cpoints:'10',
      icwr:'That’s incorrect. Inspires and Motivates Others to High Performance is linked to Interpersonal Skills.',
      icpoints:'-5',
   },{
    qstn:'Which Extraorderinary Leadership Dimension is represented by the statement, “Solves Problems and Analyzes Issues “?',
    a1:'Character',
    a2:'Leading Change',
    a3:'Interpersonal Skills',
    a4:'Focus on Results',
    a5:'Personal Capability',
    cw:'5',
    cwr:'That’s correct. Solves Problems and Analyzes Issues is linked to Personal Capability.',
    cpoints:'10',
    icwr:'That’s incorrect. Solves Problems and Analyzes Issues is linked to Personal Capability.',
    icpoints:'-5',
 },{
  qstn:'Which Extraorderinary Leadership Dimension is represented by the statement, “Practices Self-Development “?',
  a1:'Character',
  a2:'Leading Change',
  a3:'Interpersonal Skills',
  a4:'Focus on Results',
  a5:'Personal Capability',
  cw:'5',
  cwr:'That’s correct. Practices Self-Development is linked to Personal Capability.',
  cpoints:'10',
  icwr:'That’s incorrect. Practices Self-Development is linked to Personal Capability.',
  icpoints:'-5',
},{
  qstn:'Which Extraorderinary Leadership Dimension is represented by the statement, “Displays a Deep Sense of Caring for the Success of the Company and the Employees”?',
  a1:'Character',
  a2:'Leading Change',
  a3:'Interpersonal Skills',
  a4:'Focus on Results',
  a5:'Personal Capability',
  cw:'2',
  cwr:'That’s correct. Displays a Deep Sense of Caring for the Success of the Company and the Employees is linked to Character.',
  cpoints:'10',
  icwr:'That’s incorrect. Displays a Deep Sense of Caring for the Success of the Company and the Employees is linked to Character.',
  icpoints:'-5',
},{
  qstn:'Which Extraorderinary Leadership Dimension is represented by the statement, “Drives for Results “?',
  a1:'Character',
  a2:'Leading Change',
  a3:'Interpersonal Skills',
  a4:'Focus on Results',
  a5:'Personal Capability',
  cw:'2',
  cwr:'That’s correct. Drives for Results is linked to Focus on Results.',
  cpoints:'10',
  icwr:'That’s incorrect. Drives for Results is linked to Focus on Results.',
  icpoints:'-5',
},{
  title: 'leadership Dimensions'
}
],
"junAnchors":[
  {
    qstn:'Which Behavioral Anchor is represented by the statement, “Is Achievement Oriented”?',
    a1:'Builds Team',
    a2:'Takes Action',
    a3:'Values Customers & Shareowners',
    a4:'Drives Change',
    a5:'Develops Others',
    cw:'2',
    cwr:'That’s correct. Is Achievement Oriented is linked to Drives Change.',
    cpoints:'10',
    icwr:'That’s correct. Is Achievement Oriented is linked to Drives Change.',
    icpoints:'-5',
 },
 {
  qstn:'Which Behavioral Anchor is represented by the statement, “Demonstrates Breadth of Vision”?',
  a1:'Builds Team',
  a2:'Takes Action',
  a3:'Values Customers & Shareowners',
  a4:'Drives Change',
  a5:'Develops Others',
  cw:'2',
  cwr:'That’s correct. Demonstrates Breadth of Vision is linked to Takes Action.',
  cpoints:'10',
  icwr:'That’s incorrect. Demonstrates Breadth of Vision is linked to Takes Action.',
  icpoints:'-5',
},
{
 qstn:'Which Behavioral Anchor is represented by the statement, “Accomplishes Results”?',
 a1:'Builds Team',
 a2:'Takes Action',
 a3:'Values Customers & Shareowners',
 a4:'Drives Change',  
 a5:'Develops Others',
 cw:'2',
 cwr:'That’s correct. Accomplishes Results is linked to Takes Action.',
 cpoints:'10',
 icwr:'That’s correct. Accomplishes Results is linked to Takes Action.',
 icpoints:'-5',
},
{
  qstn:'Which Behavioral Anchor is represented by the statement, “Operates as a Team Player”?',
  a1:'Builds Team',
  a2:'Takes Action',
  a3:'Values Customers & Shareowners',
  a4:'Drives Change',  
  a5:'Develops Others',
  cw:'2',
  cwr:'That’s correct. Operates as a Team Player is linked to Build Teams',
  cpoints:'10',
  icwr:'That’s incorrect. Operates as a Team Player is linked to Build Teams',
  icpoints:'-5',
 },
 {
  qstn:'Which Behavioral Anchor is represented by the statement, “Supports Risk Taking”?',
  a1:'Builds Team',
  a2:'Takes Action',
  a3:'Values Customers & Shareowners',
  a4:'Drives Change',  
  a5:'Develops Others',
  cw:'2',
  cwr:'That’s correct. Supports Risk Taking is linked to Develops Others.',
  cpoints:'10',
  icwr:'That’s incorrect. Supports Risk Taking is linked to Develops Others.',
  icpoints:'-5',
 },
 {
  qstn:'Which Behavioral Anchor is represented by the statement, “Embraces Technological Impact”?',
  a1:'Builds Team',
  a2:'Takes Action',
  a3:'Values Customers & Shareowners',
  a4:'Drives Change',  
  a5:'Develops Others',
  cw:'2',
  cwr:'That’s correct. Embraces Technological Impact is linked to Drives Change.',
  cpoints:'10',
  icwr:'That’s incorrect. Embraces Technological Impact is linked to Drives Change.',
  icpoints:'-5',
 },
 {
  qstn:'Which Behavioral Anchor is represented by the statement, “Demonstrates Customer Perspective”?',
  a1:'Builds Team',
  a2:'Takes Action',
  a3:'Values Customers & Shareowners',
  a4:'Drives Change',  
  a5:'Develops Others',
  cw:'2',
  cwr:'That’s correct. Demonstrates Customer Perspective is linked to Values Customers & Shareowners.',
  cpoints:'10',
  icwr:'That’s incorrect. Demonstrates Customer Perspective is linked to Values Customers & Shareowners.',
  icpoints:'-5',
 },
 {
  qstn:'Which Behavioral Anchor is represented by the statement, “Achieves Results Through Others”?',
  a1:'Builds Team',
  a2:'Takes Action',
  a3:'Values Customers & Shareowners',
  a4:'Drives Change',  
  a5:'Develops Others',
  cw:'2',
  cwr:'That’s correct. Achieves Results Through Others is linked to Develops Others.',
  cpoints:'10',
  icwr:'That’s incorrect. Achieves Results Through Others is linked to Develops Others.',
  icpoints:'-5',
 },
 {
  qstn:'Which Behavioral Anchor is represented by the statement, “Acts Effectively in Ambiguous Situations”?',
  a1:'Builds Team',
  a2:'Takes Action',
  a3:'Values Customers & Shareowners',
  a4:'Drives Change',  
  a5:'Develops Others',
  cw:'2',
  cwr:'That’s correct. Acts Effectively in Ambiguous Situations is linked to Takes Action.',
  cpoints:'10',
  icwr:'That’s correct. Acts Effectively in Ambiguous Situations is linked to Takes Action.',
  icpoints:'-5',
 },
 {
  title: 'Behavioral Anchor'
}
],
"sepLeadership":[
  {
    qstn:'What the the most important Law of Leadership?',
    a1:'Law of the Lid',
    a2:'Law of Magnetism',
    a3:'Law of Connection',
    a4:'Law of Process',
    a5:'Law of Empowerment',
    cw:'2',
    cwr:'That’s correct. Maxwell believes the Law of the Lid is the most important law of leadership.',
    cpoints:'10',
    icwr:'That’s incorrect. Maxwell believes the Law of the Lid is the most important law of leadership.',
    icpoints:'-5',
 },
 {
  qstn:'Which Law of Leadership is represented by the statement, “Leadership develops daily, not in a day”?',
  a1:'Law of the Lid',
  a2:'Law of Magnetism',
  a3:'Law of Connection',
  a4:'Law of Process',
  a5:'Law of Empowerment',
  cw:'2',
  cwr:'That’s correct. The Law of Process reminds us that developing your leadership skill happens over time.',
  cpoints:'10',
  icwr:'That’s incorrect. The Law of Process reminds us that developing your leadership skill happens over time.',
  icpoints:'-5',
},
{
  qstn:'Which Law of Leadership is represented by the statement, “Only Secure Leaders Give Power to Others”?',
  a1:'Law of the Lid',
  a2:'Law of Magnetism',
  a3:'Law of Connection',
  a4:'Law of Process',
  a5:'Law of Empowerment',
  cw:'2',
  cwr:'That’s correct. The Law of Empowerment reminds us that secure leaders share their power with others.',
  cpoints:'10',
  icwr:'That’s incorrect. The Law of Empowerment reminds us that secure leaders share their power with others.',
  icpoints:'-5',
},
{
  qstn:'Which Law of Leadership is represented by the statement, “Activity is Not Necessarily Accomplishment”?',
  a1:'Law of the Lid',
  a2:'Law of Magnetism',
  a3:'Law of Connection',
  a4:'Law of Process',
  a5:'Law of Empowerment',
  cw:'2',
  cwr:'That’s correct. The Law of Priorities reminds us that the outcome, not just activity, is the sign of accomplishment.',
  cpoints:'10',
  icwr:'That’s incorrect. The Law of Priorities reminds us that the outcome, not just activity, is the sign of accomplishment.',
  icpoints:'-5',
},
{
  qstn:'Which Law of Leadership is represented by the statement, “A Leader’s Lasting Value is Measured by Succession”?',
  a1:'Law of the Lid',
  a2:'Law of Magnetism',
  a3:'Law of Connection',
  a4:'Law of Process',
  a5:'Law of Empowerment',
  cw:'2',
  cwr:'That’s correct. The Law of Legacy reminds us that leader’s legacy is determined by the successful succession plan of leaders that remain.',
  cpoints:'10',
  icwr:'That’s incorrect. The Law of Legacy reminds us that leader’s legacy is determined by the successful succession plan of leaders that remain.',
  icpoints:'-5',
},
{
  qstn:'Which Law of Leadership is represented by the statement, “Momentum is a Leader’s Best Friend”?',
  a1:'Law of the Lid',
  a2:'Law of Magnetism',
  a3:'Law of Connection',
  a4:'Law of Process',
  a5:'Law of Empowerment',
  cw:'2',
  cwr:'That’s correct. The Law of the Big Mo reminds us that leaders leverage momentum.',
  cpoints:'10',
  icwr:'That’s correct. The Law of the Big Mo reminds us that leaders leverage momentum.',
  icpoints:'-5',
},
{
  qstn:'Which Law of Leadership is represented by the statement, “When to Lead is as Important as What to Do and Where to Go”?',
  a1:'Law of the Lid',
  a2:'Law of Magnetism',
  a3:'Law of Connection',
  a4:'Law of Process',
  a5:'Law of Empowerment',
  cw:'2',
  cwr:'That’s correct. The Law of Timing reminds us that knowing when to lead is important.',
  cpoints:'10',
  icwr:'That’s incorrect. The Law of Timing reminds us that knowing when to lead is important.',
  icpoints:'-5',
},
{
  qstn:'Which Law of Leadership is represented by the statement, “Leaders Find a Way for the Team to Win”?',
  a1:'Law of the Lid',
  a2:'Law of Magnetism',
  a3:'Law of Connection',
  a4:'Law of Process',
  a5:'Law of Empowerment',
  cw:'2',
  cwr:'That’s correct. The Law of Victory reminds us that leaders ensure the team wins.',
  cpoints:'10',
  icwr:'That’s incorrect. The Law of Victory reminds us that leaders ensure the team wins.',
  icpoints:'-5',
},
{
  qstn:'Which Law of Leadership is represented by the statement, “A Leader’s Potential Is Determined by Those Closest to Him”?',
  a1:'Law of the Lid',
  a2:'Law of Magnetism',
  a3:'Law of Connection',
  a4:'Law of Process',
  a5:'Law of Empowerment',
  cw:'2',
  cwr:'That’s correct. The Law of the Inner Circle reminds us that leaders surround themselves with capable people.',
  cpoints:'10',
  icwr:'That’s incorrect. The Law of the Inner Circle reminds us that leaders surround themselves with capable people.',
  icpoints:'-5',
},{
  title: 'Lows - Leadership'
}
],
"augustTeamWork":[
  {
    qstn:'Which Law of Teamwork is represented by the statement, “One is too small a number to achieve greatness”?',
    a1:'Law of the Catalyst',
    a2:'Law of the Chain',
    a3:'Law of the Big Picture',
    a4:'Law of the Niche',
    a5:'Law of Significance',
    cw:'2',
    cwr:'That’s correct. The Law of Significance reminds us that it takes a team to achieve greatness.',
    cpoints:'10',
    icwr:'That’s incorrect. The Law of Significance reminds us that it takes a team to achieve greatness.',
    icpoints:'-5',
 },
 {
  qstn:'Which Law of Teamwork is represented by the statement, “Winning teams have players who make things happen”?',
  a1:'Law of the Catalyst',
  a2:'Law of the Chain',
  a3:'Law of the Big Picture',
  a4:'Law of the Niche',
  a5:'Law of Significance',
  cw:'2',
  cwr:'That’s correct. The Law of the Catalyst reminds us that winning teams have a catalyst that makes thinigs happen.',
  cpoints:'10',
  icwr:'That’s incorrect. The Law of the Catalyst reminds us that winning teams have a catalyst that makes thinigs happen.',
  icpoints:'-5',
},
{
  qstn:'Which Law of Teamwork is represented by the statement, “As the challenge escalates, the need for teamwork elevates”?',
  a1:'Law of the Big Picture',
  a2:'Law of Significance',
  a3:'Law of Mount Everest',
  a4:'Law of the Compass',
  a5:'Law of the Bad Apple',
  cw:'2',
  cwr:'That’s correct. The Law of Mount Everest reminds us that teamwork is needed most for the biggest challenges.',
  cpoints:'10',
  icwr:'That’s incorrect. The Law of Mount Everest reminds us that teamwork is needed most for the biggest challenges.',
  icpoints:'-5',
},
{
  qstn:'Which Law of Teamwork is represented by the statement, “A team that embraces a vision becomes focused, energized, and confident”?',
  a1:'Law of the Big Picture',
  a2:'Law of Significance',
  a3:'Law of Communication',
  a4:'Law of the Compass',
  a5:'Law of the Catalyst',
  cw:'2',
  cwr:'That’s correct. The Law of the Compass reminds us that having a vision for the team is critical to success.',
  cpoints:'10',
  icwr:'That’s incorrect. The Law of the Compass reminds us that having a vision for the team is critical to success.',
  icpoints:'-5',
},
{
  qstn:'Which Law of Teamwork is represented by the statement, “Interaction fuels action”?',
  a1:'Law of the Big Picture',
  a2:'Law of Significance',
  a3:'Law of the Chain',
  a4:'Law of the Compass',
  a5:'Law of the Catalyst',
  cw:'2',
  cwr:'That’s correct. The Law of Comunication reminds us of the importance communication plays in teamwork.',
  cpoints:'10',
  icwr:'That’s incorrect. The Law of Comunication reminds us of the importance communication plays in teamwork.',
  icpoints:'-5',
},
{
  qstn:'Which Law of Teamwork is represented by the statement, “The difference between two equally talented teams is leadership”?',
  a1:'Law of the Edge',
  a2:'Law of the Chain',
  a3:'Law of the Compass',
  a4:'Law of the Catalyst',
  a5:'Law of the Catalyst',
  cw:'2',
  cwr:'That’s correct. The Law of the Edge reminds us that leadership plays an important role in teamwork.',
  cpoints:'10',
  icwr:'That’s incorrect. The Law of the Edge reminds us that leadership plays an important role in teamwork.',
  icpoints:'-5',
},
{
  qstn:'Which Law of Teamwork is represented by the statement, “Investing in the team compounds over time”?',
  a1:'Law of the Edge',
  a2:'Law of the Chain',
  a3:'Law of the Dividends',
  a4:'Law of the Catalyst',
  a5:'Law of Significance',
  cw:'2',
  cwr:'That’s correct. The Law of Dividends reminds us that continued focus on teamwork compunds over time.',
  cpoints:'10',
  icwr:'That’s incorrect. The Law of Dividends reminds us that continued focus on teamwork compunds over time.',
  icpoints:'-5',
},
{
  qstn:'Which Law of Teamwork is represented by the statement, “When you’re winning, nothing hurts”?',
  a1:'Law of High Morale',
  a2:'Law of the Chain',
  a3:'Law of the Dividends',
  a4:'Law of the Catalyst',
  a5:'Law of Significance',
  cw:'2',
  cwr:'That’s correct. The Law of High Morale reminds us that winning teams have high morale.',
  cpoints:'10',
  icwr:'That’s incorrect. The Law of High Morale reminds us that winning teams have high morale.',
  icpoints:'-5',
},
{
  qstn:'Which Law of Teamwork is represented by the statement, “Shared values define the team”?',
  a1:'Law of the Big Picture',
  a2:'Law of Communication',
  a3:'Law of the Identity',
  a4:'Law of the Compass',
  a5:'Law of Significance',
  cw:'2',
  cwr:'That’s correct. The Law of Identity reminds us that it is important for the team to have shared values.',
  cpoints:'10',
  icwr:'That’s incorrect. The Law of Identity reminds us that it is important for the team to have shared values.',
  icpoints:'-5',
}
,{
  title: 'Laws Teamwork'
}
],
"octCorporateCulture":[
  {
  qstn:'Which answer is not a corporate priority?',
  a1:'Increase Shareowner Value',
  a2:'Decrease Expense At All Costs',
  a3:'Focus on the Customer',
  a4:'Focus on Employees',
  a5:'Retain customers',
  cw:'2',
  cwr:'That’s correct. The company is not interested in decreasing expense at all costs.',
  cpoints:'10',
  icwr:'That’s incorrect. The company is not interested in decreasing expense at all costs.',
  icpoints:'-5',
},
{
  qstn:'Which answer is the highest corporate priority?',
  a1:'Increase Shareowner Value',
  a2:'Optimize costs',
  a3:'Focus on the Customer',
  a4:'Focus on Employees',
  a5:'Retain customers',
  cw:'2',
  cwr:'That’s correct. The company’s top priority is focusing on customer satisfaction.',
  cpoints:'10',
  icwr:'That’s incorrect. The company’s top priority is focusing on customer satisfaction.',
  icpoints:'-5',
},
{
  qstn:'Which Corporate Culture Dimension is represented by the statement, “I am easily distracted”?',
  a1:'Accountability',
  a2:'Assume Positive Intent',
  a3:'Be Here Now',
  a4:'At Your Best',
  a5:'Gratitude',
  cw:'2',
  cwr:'That’s correct. I’m Easily Distracted is linked to Be Here Now.',
  cpoints:'10',
  icwr:'That’s incorrect. I’m Easily Distracted is linked to Be Here Now.',
  icpoints:'-5',
},
{
  qstn:'Which Corporate Culture Dimension is represented by the statement, “That statement was Offensive”?',
  a1:'Accountability',
  a2:'Assume Positive Intent',
  a3:'Be Here Now',
  a4:'At Your Best',
  a5:'Gratitude',
  cw:'2',
  cwr:'That’s correct. That statement was Offensive is linked to Assume Positive Intent.',
  cpoints:'10',
  icwr:'That’s incorrect. That statement was Offensive is linked to Assume Positive Intent.',
  icpoints:'-5',
},
{
  qstn:'Which Corporate Culture Dimension is represented by the statement, “I am rested and ready for work”?',
  a1:'Accountability',
  a2:'Assume Positive Intent',
  a3:'Be Here Now',
  a4:'At Your Best',
  a5:'Gratitude',
  cw:'2',
  cwr:'That’s correct. I am rested and ready for work is linked to At Your Best.',
  cpoints:'10',
  icwr:'That’s incorrect. I am rested and ready for work is linked to At Your Best.',
  icpoints:'-5',
},{
  title: 'Corp Culture'
}
]
}

