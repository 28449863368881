import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'
import { DashboardLayoutComponent } from './dashboard-layout/dashboard-layout.component';
import { RanksComponent } from './ranks/ranks.component';
import { TeamsComponent } from './teams/teams.component';
import { RedeemPointsComponent } from './redeem-points/redeem-points.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { TriviaComponent } from './trivia/trivia.component';
import { JeopardyComponent } from './jeopardy/jeopardy.component';
import { AppPipeModule } from 'src/app/app-pipe';
import { CorporateCultureComponent } from './corporate-culture/corporate-culture.component';
import { PunctualityComponent } from './punctuality/punctuality.component';
import { ReduceOutlookComponent } from './reduce-outlook/reduce-outlook.component';
import { ComPlanComponent } from './com-plan/com-plan.component';
import { QuestionDialogComponent } from './question-dialog/question-dialog.component';
import { TriviaLayoutComponent } from './trivia-layout/trivia-layout.component';



@NgModule({
  declarations: [DashboardLayoutComponent, RanksComponent, TeamsComponent, RedeemPointsComponent, TriviaComponent, JeopardyComponent, CorporateCultureComponent, PunctualityComponent, ReduceOutlookComponent, ComPlanComponent, QuestionDialogComponent, TriviaLayoutComponent],
  imports: [
    CommonModule,
    FormsModule,
    DashboardRoutingModule,
    AppPipeModule
  ]
})
export class DashboardModule { }
