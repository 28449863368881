import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reduce-outlook',
  templateUrl: './reduce-outlook.component.html',
  styleUrls: ['./reduce-outlook.component.scss']
})
export class ReduceOutlookComponent implements OnInit {
  constructor(private router:Router) { }

  ngOnInit() {
    
  }

  onEdit(tabNum){
    this.router.navigate([`/settings/tabs/${tabNum}`])
  }
}
