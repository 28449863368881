import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlsLayoutComponent } from './controls-layout/controls-layout.component';
import { ControlsRoutingModule } from './controls-routing.module'


@NgModule({
  declarations: [ControlsLayoutComponent],
  imports: [
    CommonModule,
    ControlsRoutingModule
  ]
})
export class ControlsModule { }
