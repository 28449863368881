import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-punctuality',
  templateUrl: './punctuality.component.html',
  styleUrls: ['./punctuality.component.scss']
})
export class PunctualityComponent implements OnInit {
  @Input() swData;
  constructor(private router:Router) { }

  ngOnInit() {
    
  }

  onEdit(tabNum){
    this.router.navigate([`/settings/tabs/${tabNum}`])
  }
}
