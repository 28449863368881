import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { spinthewheelJson } from 'src/app/app-functions';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit {
  spinWheelJson = spinthewheelJson();
  selectedSpin = "productiveTime"
  constructor(private router:Router) { }

  ngOnInit() {
  }

  onEdit(tabNum){
    this.router.navigate([`/settings/tabs/${tabNum}`])
  }
}
