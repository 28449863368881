import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-settings-tabs',
  templateUrl: './settings-tabs.component.html',
  styleUrls: ['./settings-tabs.component.scss']
})
export class SettingsTabsComponent implements OnInit {
  tabsList: Array<number> = [1, 2, 3, 4];
  currentTabIndex: number = 0;
  tabNamesJson = {
    1: "Game setup",
    2: "KPI settings",
    3: "Players setup",
    4: "Awards"
  };
  playerPositionJson = {
    1: "First",
    2: "Second",
    3: "Third",
    4: "Fourth",
    5: "Fifth",
  }
  postionAwardsList = [1, 2];
  awardPos = false;
  awardKpPos = false;
  awardTeamPos = false;
  addlevel = false;
  pageName = "Spin the wheel"
  constructor(private acRoute: ActivatedRoute, private router: Router) {

  };

  ngOnInit() {
    this.acRoute.paramMap.subscribe(
      (params: ParamMap) => {
        this.onTabClick(parseInt(params.get('id')));
      }
    )
    this.acRoute.queryParams.subscribe((data) => {
        this.pageName = data.type || "Spin the wheel";
    })
  }

  onTabClick(tabNum: number) {
    this.currentTabIndex = this.tabsList.indexOf(tabNum);
  }

  onPlusOrMinusClick(type) {
    if (type == "Add") {

    } else {

    }
  }

  redirectHome() {
    this.router.navigate([`/`]);
  }

  addLevel() {
    this.addlevel = true;
  }
}
