import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms"
import { CommonModule } from '@angular/common';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [MultiSelectComponent],
  imports: [
    NgMultiSelectDropDownModule,
    CommonModule,
    FormsModule 
  ],
  exports:[MultiSelectComponent]
})
export class MultiSelectModule { }
