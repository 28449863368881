import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-awards",
  templateUrl: "./awards.component.html",
  styleUrls: ["./awards.component.scss"],
})
export class AwardsComponent implements OnInit {
  @Input() pageName;
  badgeList = [
    "Celebrity",
    "Super Charged",
    "On Fire",
    "High Roller",
    "Planet Saver",
    "Problem Solver",
    "Local Legend",
  ];
  constructor() {}

  ngOnInit() {}
}
