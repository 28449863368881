import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-player-setup',
  templateUrl: './player-setup.component.html',
  styleUrls: ['./player-setup.component.scss']
})
export class PlayerSetupComponent implements OnInit {
  @Input() pageName;
  // Player setup
  toggleTeam = true;
  selectingTeamName = "";
  selectedPlayer = "";
  selectedTeam = "Finance"
  playersListDropdown = ["Shane Dickinson", "April Stokes", "Claude Fisher", "Joh Doe"];
  selectedPlayersList = [
    "Fidel Davis",
    "Bette Spinks",
    "Nola Pagac",
    // "Vidal Lender",
    // "Katelin Rempel",
    // "Aniya Pouros"
  ];
  teamsList = [
    "Finance",
    "Customer Care",
    "Customer Care Managers",
  ]
  teamsListDwn = [
    "Finance",
    "Customer Care",
    "Customer Care Managers",
    "Development",
    "Quality Assurance",
    "HR",
    "Claim"
  ]
  constructor() { }

  ngOnInit() {
  }

   //Players Setup 
   selectTeam() {
    if(this.selectingTeamName){
      this.teamsList.push(this.selectingTeamName);
    }
  }
  addPlayers(){
    this.selectedPlayersList.push(this.selectedPlayer);
  }

  closePlayerOrTeam(type,index) {
     if(type == 'T'){
        this.teamsList.splice(index,1);
        if(this.teamsList.indexOf(this.selectedTeam) == -1){
          this.selectedTeam = "";
        }
     }else{
        this.selectedPlayersList.splice(index,1)
     }
  }
}
