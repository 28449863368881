import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-com-plan',
  templateUrl: './com-plan.component.html',
  styleUrls: ['./com-plan.component.scss']
})
export class ComPlanComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
  }

  onEdit(tabNum){
    this.router.navigate([`/settings/tabs/${tabNum}`])
  }

}
