import { Component, Input, OnInit } from '@angular/core';
import { createKPISWtabs, createKPITriviatabs, metricList, quantityListLevel1, quantityListLevel2, recurring, rules, timeLengthList, timesList, titlesList, weightList, workdays } from 'src/app/app-functions';

@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss']
})
export class KpiComponent implements OnInit {
  @Input() pageName;
  toggleTimeDecay = true;
  selectedKpiTab = 'applicationTime'
  kpiJson = {};
  titlesList = titlesList();
  timeLengthList = timeLengthList;
  rulesList = rules;
  ql1List = quantityListLevel1;
  ql2List = quantityListLevel2;
  weightage = weightList;
  workdaysList = workdays;
  timesList = timesList;
  recurringList = recurring;
  metricList = metricList;
  constructor() { }

  ngOnInit() {
    if(this.pageName != 'Spin the wheel'){
      this.kpiJson = createKPITriviatabs();
      this.selectedKpiTab = 'applicationTime'
    }else{
      this.kpiJson = createKPISWtabs();
      this.selectedKpiTab = 'collaborationTime'
    }
  }
  addLevel(){
    this.kpiJson[this.selectedKpiTab].levels.push(this.kpiJson[this.selectedKpiTab].levels[this.kpiJson[this.selectedKpiTab].levels.length -1])
  }
}
