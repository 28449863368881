import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { jeapordyJson } from 'src/app/app-functions';

@Component({
  selector: 'app-jeopardy',
  templateUrl: './jeopardy.component.html',
  styleUrls: ['./jeopardy.component.scss']
})
export class JeopardyComponent implements OnInit {
  jeopardyJson = jeapordyJson();
  selectedjeapord = "productiveTime"
  constructor(private router:Router) { }

  ngOnInit() {
  }

  onEdit(tabNum){
    this.router.navigate([`/settings/tabs/${tabNum}`],{ queryParams : { type:'jeapordy'}})
  }

}
