import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { navPageData, navSwPageData, newQuestionsJson } from 'src/app/app-functions';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-trivia-layout',
  templateUrl: './trivia-layout.component.html',
  styleUrls: ['./trivia-layout.component.scss']
})
export class TriviaLayoutComponent implements OnInit {
  navPagesJson = navPageData();
  navSwJson = navSwPageData();
  questionsJson = newQuestionsJson;
  currentPathJson = {};
  currentQuestionsList = [];
  title : any;
  paramTxt = 'pg';
  constructor(private acRoute:ActivatedRoute) { }

  ngOnInit() {
    this.acRoute.paramMap.subscribe((e) => {
         if(e.has("pg")){
            this.paramTxt = 'pg';
            if(Object.keys(this.navPagesJson[e.get("pg")] || {}).length){
              this.currentPathJson = {...this.navPagesJson[e.get("pg")]};
              this.currentQuestionsList = this.questionsJson[e.get("pg")]
              this.currentQuestionsList.forEach((x) => {
                if(x.title){
                  this.title = x.title;
                }
              })
            }
         }else if(e.has("sw")){
            this.paramTxt = 'sw';
            if(Object.keys(this.navSwJson[e.get("sw")] || {}).length){
              this.currentPathJson = {...this.navSwJson[e.get("sw")]};
            }
         }
    });
  }

}
