import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ComPlanComponent } from "./com-plan/com-plan.component";
import { CorporateCultureComponent } from "./corporate-culture/corporate-culture.component";
import { DashboardLayoutComponent } from './dashboard-layout/dashboard-layout.component'
import { JeopardyComponent } from "./jeopardy/jeopardy.component";
import { PunctualityComponent } from "./punctuality/punctuality.component";
import { RanksComponent } from "./ranks/ranks.component";
import { RedeemPointsComponent } from "./redeem-points/redeem-points.component";
import { ReduceOutlookComponent } from "./reduce-outlook/reduce-outlook.component";
import { TeamsComponent } from "./teams/teams.component";
import { TriviaLayoutComponent } from "./trivia-layout/trivia-layout.component";
import { TriviaComponent } from "./trivia/trivia.component";

const dbRoutes: Routes = [
  {
    path: "spinTheWheel",
    component: DashboardLayoutComponent,
  },
  {
    path: "ranks",
    component: RanksComponent,
  },
  {
    path: "teams",
    component: TeamsComponent,
  },
  {
    path: "redeem-points",
    component: RedeemPointsComponent,
  },
  {
    path: "trivia",
    component: TriviaComponent,
  },
  {
    path: "jeapardy",
    component: JeopardyComponent,
  },
  {
    path: "main-trivia/:pg",
    component: TriviaLayoutComponent,
  },
  {
    path: "spinwheel/:sw",
    component: TriviaLayoutComponent,
  },
  {
    path: "trivaQ4CommunicationPlan",
    component: ComPlanComponent,
  },
  {
    path: "spinTheWheelQ4Punctuality",  
    component: PunctualityComponent,
  },
  {
    path: "spinTheWheelQ3ReduceOutlook",  
    component: ReduceOutlookComponent,
  },
  {
    path: "",
    redirectTo:"/main-trivia/octCorporateCulture",
    pathMatch:'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(dbRoutes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule {}