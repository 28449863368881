import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: "",
    loadChildren: () => import(`./feature/dashboard/dashboard.module`).then(m => m.DashboardModule)
  },
  {
    path: "controls",
    loadChildren: () => import(`./feature/controls/controls.module`).then(m => m.ControlsModule)
  },
  {
    path: "settings",
    loadChildren: () => import(`./feature/settings/settings.module`).then(m => m.SettingsModule)
  },
  {
    path: "**",
    redirectTo: "/",
    pathMatch: "full"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
