import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from "@angular/forms"
import { SettingsLayoutComponent } from './settings-layout/settings-layout.component';
import { SettingsTabsComponent } from './settings-tabs/settings-tabs.component';
import { SettingsRoutingModule } from './settings-routing.module';
import { MultiSelectModule } from "../../shared/multi-select/multi-select.module";
import { AwardsComponent } from './templates/awards/awards.component';
import { GameSetupComponent } from './templates/game-setup/game-setup.component';
import { PlayerSetupComponent } from './templates/player-setup/player-setup.component';
import { KpiComponent } from './templates/kpi/kpi.component'
import { AppPipeModule} from 'src/app/app-pipe';


@NgModule({
  declarations: [SettingsLayoutComponent, SettingsTabsComponent, AwardsComponent, GameSetupComponent, PlayerSetupComponent, KpiComponent],
  imports: [
    MultiSelectModule,
    CommonModule,
    SettingsRoutingModule,
    FormsModule, ReactiveFormsModule,
    AppPipeModule
  ]
})
export class SettingsModule { }
