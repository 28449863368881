import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {
  @Input() showSearch: boolean = true;
  @Input() onlySearch: boolean = false;
  @Input() routedata;
  @Input() isTrivia;
  headerText = "Spin the wheel";
  constructor(private router: Router) { }

  ngOnChanges() {
    console.log(this.isTrivia);
    this.headerText = this.routedata.replace("/","") || 'Spin the wheel';
  }

  ngOnInit() {
    
  }

  onLogoClick(path) {
    this.router.navigate([path])
  }
}
