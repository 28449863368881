import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit {
 @Input() placeHolder; 
 @Input() availableList = [];
 public settings = {};
 public selectedItems = [ { key:1,value:"Active Time"},{ key:2, value:"Productive Time"}]

 ngOnInit() {
   this.settings = {
     singleSelection: false, 
     idField: 'key',
     textField: 'value',
     enableCheckAll: true,
     selectAllText: 'Select All',
     unSelectAllText: 'Unselect All',
     allowSearchFilter: true,
     limitSelection: -1,
     clearSearchFilter: true,
     maxHeight: 197,
     itemsShowLimit: 3,
     searchPlaceholderText: 'Search',
     noDataAvailablePlaceholderText: 'No data available',
     closeDropDownOnSelection: false,
     showSelectedItemsAtTop: false,
     defaultOpen: false
   };
   
  }
 public onFilterChange(item: any) {
   console.log(item);
 }
 public onDropDownClose(item: any) {
   console.log(item);
 }

 public onItemSelect(item: any) {
   console.log(item);
 }
 public onDeSelect(item: any) {
   console.log(item);
 }

 public onSelectAll(items: any) {
   console.log(items);
 }
 public onDeSelectAll(items: any) {
   console.log(items);
 }
}
