import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

declare var bootstrap:any;
@Component({
  selector: 'app-question-dialog',
  templateUrl: './question-dialog.component.html',
  styleUrls: ['./question-dialog.component.scss']
})
export class QuestionDialogComponent implements OnInit,AfterViewInit {
  @Input() currentQuestionsList = [];
  myModal;
  @Input() title : any;
  constructor() { 
  }

  ngOnInit() {
    console.log(this.currentQuestionsList);
  }

  ngAfterViewInit(){
   
    this.myModal = new bootstrap.Modal(document.getElementById('questionsModalToggle'), {});
  }

  openQuestionModel(){
    this.myModal.show();
  }

}
