import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'data-ken';
  showNavbar:boolean = false;
  showSearch:boolean = false;
  listforHidingNavbar = ['settings','redeem-points','controls'];
  kpiSettings: boolean = false;
  currentRoute = "";
  isTrivia = true;
  isMainNav = false;
  constructor(private _router:Router,private appSrvc:AppService){}
  ngOnInit() {
    this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
         if(this.listforHidingNavbar.some(e => event.url.includes(e))){
            this.showNavbar = true;
         }else{
          this.showNavbar = false;
         }

         if(event.url.includes('settings')){
            this.showSearch = true;
         }else{
           this.showSearch = false;
         }
         if(event.url.includes('teams') || event.url.includes('ranks') || event.url.includes('settings')){
           this.kpiSettings = false;
           this.showNavbar = false;
         }else{
           this.kpiSettings = true;
           this.showNavbar = true;
         }

         if(event.url.includes('spinwheel/')){
            this.currentRoute = `${event.url.split("/")[2]}`;
            this.isTrivia = false;
         }else if(event.url.includes('main-trivia/')){
          this.currentRoute = `${event.url.split("/")[2]}`;
          this.isTrivia = true;
         }
        //  if(event.url.includes('Q3') || event.url.includes('Q4')){
        //   this.isMainNav = false;
        // }else{
        //   this.isMainNav = true;
        // }
      }
    });
  }
}
