import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { QuestionDialogComponent } from '../question-dialog/question-dialog.component';

@Component({
  selector: 'app-corporate-culture',
  templateUrl: './corporate-culture.component.html',
  styleUrls: ['./corporate-culture.component.scss']
})
export class CorporateCultureComponent implements OnInit {
  @Input() dialogRef:QuestionDialogComponent;
  @Input() triviaData;
  constructor(private router:Router) { }

  ngOnInit() {
  }

  onEdit(tabNum){
    this.router.navigate([`/settings/tabs/${tabNum}`])
  }

}
