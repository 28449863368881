import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";

@Injectable({
    providedIn:'root'
})

export class AppService  {
   appSubject = new ReplaySubject();
   appObservable = this.appSubject.asObservable();
   constructor(){

   }
}