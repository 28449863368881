import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {
  chatMsg:string = "";
  msgsList = [
    {
      img:"assets/images/ranks/pic1.png", 
      msg:"Hello looks like you guy are not"
    }
  ]
  constructor() { }

  ngOnInit() {
  }

  onMsgSendClick(val){
    this.msgsList.push({
      img:"assets/images/profile-pic.png",
      msg:this.chatMsg
    });
    this.chatMsg = "";
  }


}
