import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { triviaJson } from '../../../app-functions';

@Component({
  selector: 'app-trivia',
  templateUrl: './trivia.component.html',
  styleUrls: ['./trivia.component.scss']
})
export class TriviaComponent implements OnInit {
  triviaJson = triviaJson();
  selectedTrivia = "productiveTime"
  constructor(private router:Router) { }

  ngOnInit() {
  }

  onEdit(tabNum){
    this.router.navigate([`/settings/tabs/${tabNum}`],{ queryParams : { type:'trivia'}})
  }

}
