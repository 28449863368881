import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { quantityListLevel1, quantityListLevel2 } from './app-functions';

@Pipe({ name: 'convertCapitalise' })
export class ConvertCapitalisePipe implements PipeTransform {
  transform(text,args) {
    if(!args){
      if(Array.isArray(text)){
        text = text.map((item) => {
          const result = item.replace(/([A-Z])/g, " $1");
          const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
          return finalResult;
        })
        return text;
      }else{
        const result = text.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult
      }
    }else{
      if(!args[0]){
         return quantityListLevel1           
      }else{
        return quantityListLevel2           
      }
    }
    
  }
}

@NgModule({
    declarations: [ConvertCapitalisePipe],
    imports: [
      CommonModule,
    ],
    exports: [
      ConvertCapitalisePipe
    ],
  })
  export class AppPipeModule { }
  
